import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Timer from "utils/timer";

const Authmiddleware = ({
  component: Component,
  user,
  hasCards,
  path,
  layout: Layout,
  supervisor,
  type,
}) => (
  <Route
    path={path}
    render={(props) => {
      if (type && type === "supervisor") {
        if (supervisor === null) {
          return (
            <Redirect
              to={{
                pathname: "/supervisor-login",
                state: { from: props.location },
              }}
            />
          );
        }
      } else {
        if (user === null || hasCards === null) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }

      return (
        <Layout menu={true}>
          {type === "induction" && <Timer />}
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    supervisor: state.auth.supervisor,
    user: state.auth.user,
    hasCards: state.auth.hasCards,
  };
};

export default withRouter(connect(mapStateToProps)(Authmiddleware));
