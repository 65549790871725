import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectLanguage } from "store/language/actions";

import {
  makeSelectSelectedLanguage,
  makeSelectLanguages,
} from "store/language/selector";
//styles
import classes from "./languageSelection.module.scss";

//components
import { FormattedMessage } from "react-intl";
import Footer from "components/UI/footer";
import Button from "components/UI/button";

//utils
import { isNil } from "lodash";
import queryString from "query-string";

import RedTick from "./red-tick.svg";

const LanguageSelection = ({
  languages,
  history,
  selectedLanguage,
  setSelectLanguage,
}) => {
  const [selectedSite, setSite] = useState();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [redirectRoute, setRedirectRoute] = useState(null);
  const [siteManager, setSiteManager] = useState(null);

  const languageList = {
    eng: "English",
    fil: "Filipino",
    chi: "中国人",
    mao: "Te Reo Māori",
  };

  useEffect(() => {
    if (languages?.length) {
      console.log("languages", languages);
      const dfList = languages.map((item) => {
        return {
          value: item.code,
          label: !isNil(languageList[item?.code])
            ? `${item.name} / ${languageList[item.code]}`
            : item.name,
        };
      });

      setLanguageOptions(dfList);
    }
  }, [languages]);

  useEffect(() => {
    if (selectedLanguage) {
      setSelectedOption(selectedLanguage?.code);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { site, redirect, sitemanager } = parsed;
    setSite(site);
    setRedirectRoute(redirect);
    setSiteManager(sitemanager);
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        <FormattedMessage
          defaultMessage={"Select desired language"}
          id={"language.main.title"}
        />
      </h1>
      <div className={classes.FormWrapper}>
        {languageOptions?.map((item, index) => (
          <div key={index} className={classes.personaItem}>
            <div>
              <div
                className={classes.checkbox}
                onClick={() => {
                  setSelectedOption(item.value);
                  setSelectLanguage({
                    code: item.value,
                    name: item.label,
                  });
                }}
              >
                {item.value === selectedOption && (
                  <img alt={"tick"} src={RedTick} className={classes.tick} />
                )}
              </div>
            </div>
            <div className={classes.textWrapper}>
              <span className={classes.personaTitle}>{item.label}</span>
            </div>
          </div>
        ))}
      </div>
      {selectedOption && (
        <Footer transparent>
          <Button
            formatID="induction.button.continue"
            id="apply-button"
            text="Continue"
            type="submit"
            disabled={isNil(selectedOption)}
            onClick={() => {
              if (isNil(siteManager)) {
                history.push(
                  `/persona-selection?site=${selectedSite}&redirect=${redirectRoute}`
                );
              } else {
                history.push(
                  `/persona-selection?site=${selectedSite}&sitemanager=${siteManager}&redirect=${redirectRoute}`
                );
              }
            }}
          />
        </Footer>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  selectedLanguage: makeSelectSelectedLanguage(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
