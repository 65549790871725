import React, { useState, useEffect } from "react";
import classes from "./PersonaSelection.module.scss";
import Footer from "components/UI/footer";
import Button from "components/UI/button";
import RedTick from "./red-tick.svg";
import { isNil } from "lodash";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";

const PersonaSelection = ({ history }) => {
  const [selectedPersona, setSelectedPersona] = useState();
  const [selectedSite, setSite] = useState();
  const [redirectRoute, setRedirectRoute] = useState();
  const [siteManager, setSiteManager] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { site, redirect, sitemanager } = parsed;
    setSite(site);
    setRedirectRoute(redirect);
    setSiteManager(sitemanager);
  }, []);

  const PersonaList = [
    {
      id: 1,
      title: "Contractors",
      titleFormatID: "persona.title.1",
      desc:
        "I am contracted to, or employed by an external Company to work on Fletcher Living sites",
      descFormatID: "persona.description.1",
    },
    {
      id: 3,
      title: "Staff",
      titleFormatID: "persona.title.3",
      desc:
        "I am a Fletcher Residential employee, or am employed by one of the wider Fletcher Building businesses.",
      descFormatID: "persona.description.3",
    },
    {
      id: 2,
      title: "Visitor",
      titleFormatID: "persona.title.2",
      desc: "I am a visitor to your site.",
      descFormatID: "persona.description.2",
    },
  ];

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        {" "}
        <FormattedMessage
          defaultMessage={"Select appropriate persona"}
          id={"persona.main.title"}
        />
      </h1>
      {PersonaList.map((item, index) => (
        <div key={index} className={classes.personaItem}>
          <div>
            <div
              className={classes.checkbox}
              onClick={() => {
                setSelectedPersona(item.id);
              }}
            >
              {item.id === selectedPersona && (
                <img alt={"tick"} src={RedTick} className={classes.tick} />
              )}
            </div>
          </div>
          <div className={classes.textWrapper}>
            <span className={classes.personaTitle}>
              <FormattedMessage
                defaultMessage={item.title}
                id={item.titleFormatID}
              />
            </span>
            <span className={classes.personaDesc}>
              <FormattedMessage
                defaultMessage={item.desc}
                id={item.descFormatID}
              />
            </span>
          </div>
        </div>
      ))}
      <Footer transparent>
        <Button
          formatID="login.button"
          id="apply-button"
          text="Continue"
          type="submit"
          disabled={isNil(selectedPersona)}
          onClick={() => {
            if (selectedPersona === 3) {
              history.push(
                `/staff-login?userType=${selectedPersona}&site=${selectedSite}&redirect=${redirectRoute}`
              );
            } else {
              if (isNil(siteManager)) {
                history.push(
                  `/login?userType=${selectedPersona}&site=${selectedSite}&redirect=${redirectRoute}`
                );
              } else {
                history.push(
                  `/login?userType=${selectedPersona}&site=${selectedSite}&sitemanager=${siteManager}&redirect=${redirectRoute}`
                );
              }
            }
          }}
        />
      </Footer>
    </div>
  );
};

export default PersonaSelection;
