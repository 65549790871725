// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CHECK_OUT_REQUEST,
  CHECK_OUT_REQUEST_SUCCESS,
  CHECK_OUT_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/public";
import { isNil } from "lodash";
import { push } from "connected-react-router";

function* checkOut(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();
    params.append("site", values.site);
    const token = localStorage.getItem("AUTH_TOKEN");
    const access_token = localStorage.getItem("access_token");

    console.log("token", token);

    console.log("access token", access_token);

    delete axios.defaults.headers.common["X-Access-Token"];
    delete axios.defaults.headers.common["X-Token"];

    if (!isNil(token) && isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = token;
    } else if (!isNil(access_token)) {
      axios.defaults.headers.common["X-Access-Token"] = access_token;
    } else {
      yield put(
        push(`/language-selection?site=${values?.site}&redirect=check-out`)
      );
    }
    const response = yield call(axios.post, "/check-out/", params);

    yield put({
      type: CHECK_OUT_REQUEST_SUCCESS,
      data: response.data,
    });

    if (!isNil(values?.callBack)) {
      values.callBack();
    }
  } catch (error) {
    yield put({
      type: CHECK_OUT_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* checkOutSaga() {
  yield takeLatest(CHECK_OUT_REQUEST, checkOut);
}
