import React, { useState } from "react";
import classes from "./modal.module.scss";
import ReactLoading from "react-loading";
import Input from "../input";

const Modal = ({
  open,
  cancel,
  action,
  loading,
  callback,
  companyOptions,
  selectedRows,
}) => {
  const [option, setOption] = useState(false);
  const [name, setName] = useState("");
  const [combineWith, setCombineWith] = useState(false);

  console.log("CALLBACK", callback);

  return (
    <>
      {open && (
        <>
          <div className={classes.modalContainer}>
            <div
              className={classes.modal}
              style={{
                width: "608px",
                height: "482px",
                alignItems: "center",
              }}
            >
              <>
                <div className={classes.headerContainer}>
                  <span>Merge duplicate company records</span>
                </div>
                <span className={classes.selectText}>Select merge method</span>
                <div
                  style={{ width: "100%", margin: "0 auto" }}
                  className={classes.bodyContainer}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      height: "60px",
                    }}
                  >
                    <div className={classes.tabContainer}>
                      <div
                        onClick={() => setOption(false)}
                        className={option ? classes.tab1 : classes.activetab1}
                      >
                        <span>With existing company</span>
                      </div>
                      <div
                        onClick={() => setOption(true)}
                        style={{ borderRadius: " 0px 22px 22px 0px" }}
                        className={!option ? classes.tab1 : classes.activetab1}
                      >
                        <span>Create a new company record</span>
                      </div>
                    </div>
                  </div>
                  <span className={classes.desc}>
                    {option
                      ? "Merge selected companies by creating a new company record. Unwanted companies record will be deleted forever."
                      : "Merge selected companies with an existing company record from the dropdown selector. Unwanted company record will be deleted forever and merged with the desired one. "}
                  </span>
                  <div className={classes.inputCont}>
                    {option ? (
                      <>
                        <div
                          style={{
                            marginTop: "32px",
                            marginBottom: "32px",
                            width: "100%",
                          }}
                        >
                          <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            label={"New company name"}
                            placeholder={"Type new company name"}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Input
                          tabSelectsValue={combineWith}
                          value={companyOptions.filter(
                            (option) => option.value === combineWith
                          )}
                          type={"select"}
                          onChange={(val) => {
                            setCombineWith(val.value);
                          }}
                          label={"Select Company"}
                          placeholder={"Select Company"}
                          options={companyOptions.filter((b) =>
                            selectedRows.find((a) => a.id === b.value)
                          )}
                          component={Input}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={classes.footerContainer}>
                  <div
                    className={classes.cancelButton}
                    onClick={() => cancel()}
                  >
                    <span>Cancel</span>
                  </div>
                  <div
                    onClick={() => {
                      if (!option) {
                        action({
                          ids: selectedRows.map((item) => item.id),
                          combine_with: combineWith,
                          callBack: () => {
                            callback();
                            cancel();
                          },
                        });
                      } else {
                        action({
                          ids: selectedRows.map((item) => item.id),
                          name: name,
                          callBack: () => {
                            callback();
                            cancel();
                          },
                        });
                      }
                    }}
                    className={classes.sendButton}
                  >
                    {!loading ? (
                      <span>Merge</span>
                    ) : (
                      <ReactLoading
                        width={"20px"}
                        height={"20px"}
                        color={"#ffffff"}
                        type={"spin"}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

export default Modal;
