import React, { useEffect } from "react";
import { connect } from "react-redux";

//action start
import { removeToken } from "store/auth/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "store/auth/selector";
//selectors end

//components start
const DummyLogout = ({ removeTokenReq, history }) => {
  useEffect(() => {
    removeTokenReq();
    history.push("/login");
    window.localStorage.removeItem("AUTH_TOKEN");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("expiry_date");
  }, []);
  return <div></div>;
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    removeTokenReq: (val) => dispatch(removeToken(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DummyLogout);
