import {
  GET_CONTRACTOR_LIST_REQUEST,
  GET_CONTRACTOR_STATS_REQUEST,
  GET_DASHBOARD_REQUEST,
  GET_PERMISSIONS_REQUEST,
  GET_ROLES_REQUEST,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_USERS_REQUEST,
  UPDATE_USER,
  DELETE_USER,
  CREATE_USER,
  IMPORT_STAFF,
  GET_USER_BY_ID_REQUEST,
  GET_ROLE_BY_ID_REQUEST,
  GET_ALL_CONTRACTORS_BY_SITE,
  GET_COMPANIES,
  CREATE_COMPANY,
  MERGE_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  GET_SITE_REQUEST,
  CREATE_SITE,
  UPDATE_SITE,
  DELETE_SITE,
  GET_SITE_BY_ID_REQUEST,
  GET_SITE_STATS_REQUEST,
  GET_CONTRACTORS_BY_SITE,
  UPDATE_CONTRACTOR_REQUEST,
  GET_REPORTS,
  GET_ALL_REPORTS,
  GET_WEEKLY_ACTIVITY,
  GET_GENERIC_STAFF_LIST_REQUEST,
  GET_VISITOR_LIST_REQUEST,
  GET_CONTRACTOR_CARDS,
  GET_CARD_REPORTS,
  GET_ALL_CARD_REPORTS,
  GET_ALL_ATTENDANCE_REPORTS,
  GET_ATTENDANCE_REPORTS,
  CREATE_CONTRACTOR_CARD,
  UPDATE_CONTRACTOR_CARD,
  GET_REGION_REQUEST,
  CREATE_REGION_REQUEST,
  EDIT_REGION_REQUEST,
  DELETE_REGION_REQUEST,
  GET_ATTENDANCE_DETAIL,
  SET_DATE_FILTER,
  GET_ALL_ATTENDANCE_DETAIL_REPORT,
} from "./constants";

export const getContactorListRequest = (payload) => {
  return {
    type: GET_CONTRACTOR_LIST_REQUEST,
    ...payload,
  };
};

export const getContractorStats = (payload) => {
  return {
    type: GET_CONTRACTOR_STATS_REQUEST,
    ...payload,
  };
};

export const getDashboardRequest = (payload) => {
  return {
    type: GET_DASHBOARD_REQUEST,
    ...payload,
  };
};

export const getPermissionsRequest = (payload) => {
  return {
    type: GET_PERMISSIONS_REQUEST,
    ...payload,
  };
};

export const createRole = (payload) => {
  return {
    type: CREATE_ROLE,
    ...payload,
  };
};

export const updateRole = (payload) => {
  return {
    type: UPDATE_ROLE,
    ...payload,
  };
};

export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    ...payload,
  };
};

export const getRolesRequest = (payload) => {
  return {
    type: GET_ROLES_REQUEST,
    ...payload,
  };
};

export const getUsersRequest = (payload) => {
  return {
    type: GET_USERS_REQUEST,
    ...payload,
  };
};

export const createUser = (payload) => {
  return {
    type: CREATE_USER,
    ...payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    ...payload,
  };
};

export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
    ...payload,
  };
};

export const importStaff = (payload) => {
  return {
    type: IMPORT_STAFF,
    ...payload,
  };
};

export const getRoleByIdRequest = (payload) => {
  return {
    type: GET_ROLE_BY_ID_REQUEST,
    ...payload,
  };
};

export const getUserByIdRequest = (payload) => {
  return {
    type: GET_USER_BY_ID_REQUEST,
    ...payload,
  };
};

export const getCompaniesRequest = (payload) => {
  return {
    type: GET_COMPANIES,
    ...payload,
  };
};
export const createSite = (payload) => {
  return {
    type: CREATE_SITE,
    ...payload,
  };
};

export const createCompany = (payload) => {
  return {
    type: CREATE_COMPANY,
    ...payload,
  };
};
export const updateSite = (payload) => {
  return {
    type: UPDATE_SITE,
    ...payload,
  };
};

export const mergeCompany = (payload) => {
  return {
    type: MERGE_COMPANY,
    ...payload,
  };
};
export const deleteSite = (payload) => {
  return {
    type: DELETE_SITE,
    ...payload,
  };
};
export const deleteCompany = (payload) => {
  return {
    type: DELETE_COMPANY,
    ...payload,
  };
};
export const updateCompany = (payload) => {
  return {
    type: UPDATE_COMPANY,
    ...payload,
  };
};
export const getSitesRequest = (payload) => {
  return {
    type: GET_SITE_REQUEST,
    ...payload,
  };
};

export const getSiteById = (payload) => {
  return {
    type: GET_SITE_BY_ID_REQUEST,
    ...payload,
  };
};

export const getSiteStatsRequest = (payload) => {
  return {
    type: GET_SITE_STATS_REQUEST,
    ...payload,
  };
};

export const getContractorsBySite = (payload) => {
  return {
    type: GET_CONTRACTORS_BY_SITE,
    ...payload,
  };
};

export const getAllContractorsBySite = (payload) => {
  return {
    type: GET_ALL_CONTRACTORS_BY_SITE,
    ...payload,
  };
};

export const updateContractorRequest = (payload) => {
  return {
    type: UPDATE_CONTRACTOR_REQUEST,
    ...payload,
  };
};

export const getReportsRequest = (payload) => {
  return {
    type: GET_REPORTS,
    ...payload,
  };
};

export const getAllReportsRequest = (payload) => {
  return {
    type: GET_ALL_REPORTS,
    ...payload,
  };
};

export const getCardReportsRequest = (payload) => {
  return {
    type: GET_CARD_REPORTS,
    ...payload,
  };
};

export const getAllCardReportsRequest = (payload) => {
  return {
    type: GET_ALL_CARD_REPORTS,
    ...payload,
  };
};

export const getAttendanceReportsRequest = (payload) => {
  return {
    type: GET_ATTENDANCE_REPORTS,
    ...payload,
  };
};

export const getAllAttendaceReportsRequest = (payload) => {
  return {
    type: GET_ALL_ATTENDANCE_REPORTS,
    ...payload,
  };
};

export const getWeeklyActivity = (payload) => {
  return {
    type: GET_WEEKLY_ACTIVITY,
    ...payload,
  };
};

export const getStaffListRequest = (payload) => {
  return {
    type: GET_GENERIC_STAFF_LIST_REQUEST,
    ...payload,
  };
};

export const getVisitorListRequest = (payload) => {
  return {
    type: GET_VISITOR_LIST_REQUEST,
    ...payload,
  };
};
export const getContractorCardsRequest = (payload) => {
  return {
    type: GET_CONTRACTOR_CARDS,
    ...payload,
  };
};

export const getAttendanceDetailRequest = (payload) => {
  return {
    type: GET_ATTENDANCE_DETAIL,
    ...payload,
  };
};

export const getAllAttendanceDetailRequest = (payload) => {
  return {
    type: GET_ALL_ATTENDANCE_DETAIL_REPORT,
    ...payload,
  };
};

export const createContractorCard = (payload) => {
  return {
    type: CREATE_CONTRACTOR_CARD,
    ...payload,
  };
};

export const getRegionRequest = (payload) => {
  return {
    type: GET_REGION_REQUEST,
    ...payload,
  };
};

export const updateContractorCard = (payload) => {
  return {
    type: UPDATE_CONTRACTOR_CARD,
    ...payload,
  };
};
export const createRegionRequest = (payload) => {
  return {
    type: CREATE_REGION_REQUEST,
    ...payload,
  };
};
export const editRegionRequest = (payload) => {
  return {
    type: EDIT_REGION_REQUEST,
    ...payload,
  };
};
export const deleteRegionRequest = (payload) => {
  return {
    type: DELETE_REGION_REQUEST,
    ...payload,
  };
};

export const setDateFilterReq = (payload) => {
  return {
    type: SET_DATE_FILTER,
    ...payload,
  };
};
