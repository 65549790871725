import React, { useEffect } from "react";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import classes from "./complete.module.scss";
import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";
import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Tick from "./completionTick.svg";
import { FormattedMessage } from "react-intl";

const Complete = (props) => {
  useEffect(() => {
    props.setSection({
      section: 11,
      callBack: () =>
        props.getSectionTimeReq({ induction_id: "2", section: 11 }),
    });
    props.getProgress();
  }, []);

  useEffect(() => {
    if (props.progressData.section < 11) {
      props.setProgress({ section: 11, progress: 0, induction_id: 2 });
    }
  }, [props.progressData]);
  return (
    <div style={{ width: "90%", margin: "10px auto" }}>
      <span className={classes.Title}>
        <FormattedHTMLMessage id={"completion.title"} />
      </span>
      <div
        style={{
          marginTop: "4vh",
          marginBottom: "4vh",
        }}
      >
        <div className={classes.CheckboxWrapper}>
          <img src={Tick} alt={"Tick"} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0px 30px 0px 30px",
        }}
      >
        <span className={classes.Content}>
          <FormattedMessage id={"completion.description.1"} />
        </span>
        <span className={classes.Content}>
          <FormattedMessage id={"completion.description.2"} />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
