// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_REPORTS_SUCCESS,
  GET_REPORTS_ERROR,
  GET_REPORTS,
} from "../../constants";
import axios from "../../../../services/api";

function* getReports(data) {
  try {
    const params = new URLSearchParams();
    if (data.startdate) {
      params.append("startdate", data.startdate);
    }
    if (data.enddate) {
      params.append("enddate", data.enddate);
    }
    if (data.companyIds) {
      params.append("companyIds", data.companyIds);
    }
    if (data.statusIds) {
      params.append("statusIds", data.statusIds);
    }
    if (data.siteIds) {
      params.append("siteIds", data.siteIds);
    }
    if (data.regionIds) {
      params.append("regionIds", data.regionIds);
    }
    if (data.sort) {
      params.append("sort", data.sort);
    }
    if (data.page) {
      params.append("page", data.page);
    }
    if (data.search) {
      params.append("search", data.search);
    }

    const response = yield call(axios.get, "/reports-contractor/", { params });
    if (response.data.error) {
      yield put({
        type: GET_REPORTS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_REPORTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_REPORTS_ERROR,
      data: error,
    });
  }
}

export default function* getReportsSaga() {
  yield takeLatest(GET_REPORTS, getReports);
}
