import React, { useEffect, useState } from "react";
import classes from "./checkin.module.scss";
import { types } from "./types";
import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//action start
import { checkInRequest, checkInStatusRequest } from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectUser,
  makeSelectCheckInStatus,
  makeSelectSupervisor,
  makeSelectCheckInStatusCheck,
} from "store/auth/selector";
//selectors end
import { isNil } from "lodash";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Loading from "components/Supervisor/loading";
import { msToTime } from "utils/helper";
import Layout from "layouts/contractor";

const CheckIn = ({
  loading,
  user,
  checkIn,
  status,
  history,
  error,
  checkCheckInStatus,
  statusCheck,
}) => {
  const [site, setSite] = useState(null);
  const [time, setTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);
  const [data, setData] = useState(null);

  const [variable, setVariable] = useState();

  useEffect(() => {
    const counter = () => {
      setTimeDiff(timeDiff + 1000);
      setTime(msToTime(timeDiff + 1000));
    };
    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (!isNil(parsed?.id)) {
      checkCheckInStatus({
        id: parsed?.id,
        site: parsed?.site,
        token: user?.token,
        status: status,
        error: error,
      });
    } else {
      checkIn({ site: parsed?.site, token: user?.token });
    }
    setSite(parsed?.site);
    const access_token = window.localStorage.getItem("access_token");
    if (isNil(user?.token) && isNil(access_token)) {
      history.push(
        `/language-selection?site=${parsed?.site}&redirect=check-in`
      );
    }
  }, []);

  useEffect(() => {
    if (!isNil(statusCheck)) {
      var date = new Date(status?.check_in);
      var now = new Date();

      const diffTime = Math.abs(date - now);
      setTimeDiff(diffTime);
      setTime(msToTime(diffTime));

      const selectedVariable = types.find(
        (item) => item.id === status.messageId
      );
      setVariable(selectedVariable);
      setData(status);
    }
  }, [statusCheck]);

  useEffect(() => {
    if (!isNil(status)) {
      var date = new Date(status?.check_in);
      var now = new Date();

      const diffTime = Math.abs(date - now);
      setTimeDiff(diffTime);
      setTime(msToTime(diffTime));

      const selectedVariable = types.find(
        (item) => item.id === status.messageId
      );
      setVariable(selectedVariable);
      setData(status);
    }
  }, [status]);

  useEffect(() => {
    if (!isNil(error)) {
      const selectedVariable = types.find(
        (item) => item.id === error.messageId
      );

      setVariable(selectedVariable);
      setData(error);
    }
  }, [error]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Layout menu>
        {!isNil(variable) && !isNil(data) && (
          <div
            className={classes.container}
            style={{ background: variable?.color }}
          >
            {variable?.timeCount && (
              <div className={classes.timeCountContainer}>
                <span>{time}</span>
              </div>
            )}
            <img
              alt={"icon"}
              className={classes.icon}
              style={{
                width: variable?.icon?.iconWidth,
                height: variable.icon.iconHeight,
              }}
              src={variable.icon.icon}
            />
            <span className={classes.name}>
              {user && user?.first_name} {user && user?.last_name}
            </span>
            {variable?.title && (
              <span className={classes.title}>
                <FormattedMessage
                  defaultMessage={variable?.title}
                  id={variable.titleId}
                />
              </span>
            )}
            {variable?.dateText && (
              <span className={classes.description}>
                <FormattedMessage
                  defaultMessage={variable?.dateText}
                  id={variable.dateTextId}
                  values={{ day: data.remaining_days }}
                />
              </span>
            )}
            {variable?.htmlDescription && (
              <span className={classes.htmlDescription}>
                <FormattedHTMLMessage
                  defaultMessage={variable?.htmlDescription}
                  id={variable.htmlDescription}
                />
              </span>
            )}

            {!isNil(variable?.description) && (
              <span className={classes.description}>
                {variable?.title && (
                  <span className={classes.description}>
                    <FormattedMessage
                      defaultMessage={variable.description}
                      id={variable.descriptionId}
                    />
                  </span>
                )}
              </span>
            )}
            {!isNil(variable?.subDescription) && (
              <span className={classes.subDescription}>
                <FormattedMessage
                  defaultMessage={variable.subDescription}
                  id={variable.subDescriptionId}
                />
              </span>
            )}
            <div className={classes.infoContainer}>
              <div className={classes.infoWrapper}>
                <span className={classes.infoTitle}>
                  <FormattedMessage
                    defaultMessage={"Company"}
                    id={"checkin.company"}
                  />
                </span>
                <span className={classes.text}>
                  {data?.site?.company?.name}
                </span>
              </div>

              <div className={classes.infoWrapper}>
                <span className={classes.infoTitle}>
                  <FormattedMessage
                    defaultMessage={"Site"}
                    id={"checkin.site"}
                  />
                </span>
                <span className={classes.text}>{data?.site?.name}</span>
              </div>
            </div>
            {variable?.footer && (
              <>
                <div
                  className={classes.footer}
                  onClick={() => {
                    if (variable?.key === "pending_induction" && isNil(user)) {
                      history.push(`/login?site=${site}`);
                    } else {
                      history.push(`${variable?.pushPath}?site=${site}`);
                    }
                  }}
                >
                  <span>
                    <FormattedMessage
                      defaultMessage={variable.footer.text}
                      id={variable.footer.textID}
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  user: makeSelectUser(),
  status: makeSelectCheckInStatus(),
  statusCheck: makeSelectCheckInStatusCheck(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    checkIn: (val) => dispatch(checkInRequest(val)),
    checkCheckInStatus: (val) => dispatch(checkInStatusRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckIn)
);
