import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import "assets/scss/style.scss";
import "assets/css/toastify.css";

//routes
import { routes, authRoutes } from "routes/allRoutes";

//middleware start
import Authmiddleware from "middleware/auth";
import NonAuthmiddleware from "middleware/nonAuth";

//midleware end

import { getLanguagesRequest } from "store/language/actions";
import {
  makeSelectLanguages,
  makeSelectLoading,
  makeSelectSelectedLanguage,
} from "./store/language/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Layout from "layouts/contractor";
import SupervisorLayout from "layouts/supervisor";
import CheckIn from "pages/CheckIn";
import SiteInduction from "pages/SiteInduction";

import Loading from "components/UI/loading";
import Splash from "pages/Splash";
import { enableES5 } from "immer";

import { icons } from "assets/icons";
import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";
import { IntlProvider } from "react-intl";
import enTranslations from "translations/en.json";
import { Route } from "react-router-dom";
import ErrorBoundary from "components/UI/errorBoundary";

enableES5();

React.icons = icons;

export const success = (val) => toast.success(`${val}`);
export const error = (val) => toast.error(`${val}`);

const App = ({ selectedLanguage, getLanguages, languages }) => {
  const isMain = window.location.pathname === "/";

  const [isSplashVisible, setSplashVisible] = useState(isMain ? true : false);
  const [langs, setLangs] = useState(null);

  const trackingId = "G-240XE9FT93";

  useEffect(() => {
    ReactGA.initialize(trackingId);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    if (languages !== null) {
      const parsedlangs = languages.map((item) => {
        return {
          ...item,
          data: JSON.parse(item.data),
        };
      });
      setLangs(parsedlangs);
    }
  }, [languages]);

  return (
    <>
      <IntlProvider
        messages={
          selectedLanguage
            ? langs &&
              langs?.find((item) => item.code === selectedLanguage.code)?.data
            : enTranslations
        }
        locale={selectedLanguage ? selectedLanguage.code : "eng"}
        defaultLocale="eng"
      >
        <React.Suspense fallback={Loading}>
          {isSplashVisible ? (
            <Splash />
          ) : (
            <ErrorBoundary>
              <Switch>
                <Route path={"/check-in"} component={CheckIn} />
                <Route path={"/site-induction"} component={SiteInduction} />

                {authRoutes.map((route, idx) => (
                  <NonAuthmiddleware
                    type={route.type}
                    path={route.path}
                    layout={
                      route.type === "supervisor" ? SupervisorLayout : Layout
                    }
                    component={route.component}
                    key={idx}
                  />
                ))}

                {routes.map((route, idx) => (
                  <Authmiddleware
                    type={route.type}
                    path={route.path}
                    layout={
                      route.type === "supervisor" ? SupervisorLayout : Layout
                    }
                    component={route.component}
                    key={idx}
                  />
                ))}
              </Switch>
            </ErrorBoundary>
          )}
          <ToastContainer />
        </React.Suspense>
      </IntlProvider>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  loading: makeSelectLoading(),
  selectedLanguage: makeSelectSelectedLanguage(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: (val) => dispatch(getLanguagesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
