import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAuth = (state) => state.auth || initialState;

const makeSelectError = () =>
  createSelector(selectAuth, (state) => state.error);

const makeSelectUser = () => createSelector(selectAuth, (state) => state.user);

const makeSelectSupervisor = () =>
  createSelector(selectAuth, (state) => state.supervisor);

const makeSelectReason = () =>
  createSelector(selectAuth, (state) => state.reason);

const makeSelectLoading = () =>
  createSelector(selectAuth, (state) => state.loading);

const makeSelectMe = () => createSelector(selectAuth, (state) => state.me);

const makeSelectCheckInStatus = () =>
  createSelector(selectAuth, (state) => state.checkIn);

const makeSelectCheckInStatusCheck = () =>
  createSelector(selectAuth, (state) => state.makeSelectCheckInStatus);

const makeSelectCheckOutStatus = () =>
  createSelector(selectAuth, (state) => state.checkOut);

const makeSelectSiteInduction = () =>
  createSelector(selectAuth, (state) => state.siteInduction);

const makeSelectCovidQuestions = () =>
  createSelector(selectAuth, (state) => state.covidQuestions);

export {
  selectAuth,
  makeSelectError,
  makeSelectUser,
  makeSelectLoading,
  makeSelectReason,
  makeSelectSupervisor,
  makeSelectMe,
  makeSelectCheckInStatus,
  makeSelectCheckOutStatus,
  makeSelectSiteInduction,
  makeSelectCovidQuestions,
  makeSelectCheckInStatusCheck,
};
