import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./filterSelector.module.scss";
import { isEmpty, isNil } from "lodash";
import ArrowIcon from "./arrow.svg";
import SearchIcon from "./search.svg";
import CloseIcon from "./close.svg";
import ReactLoading from "react-loading";

const FilterSelector = ({
  options,
  onSelect,
  disabled,
  searchable,
  onSearch,
  loading,
  label,
  placeholder,
  value,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  console.log("options", options);

  console.log("value", value);

  useEffect(() => {
    if (!isNil(value) && value.label !== inputValue) {
      console.log("value", value);
      setInputValue(value.label);
    }
  }, [value]);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div>
        <label className={classes.BlockLabel}>{label}</label>
        <div className={cn(classes.selectbox)}>
          <button
            className={cn(classes.selectbox_head)}
            onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
          >
            {searchable && (
              <>
                <div className={classes.filterInput}>
                  <img
                    className={classes.searchIcon}
                    alt={"Search Icon"}
                    src={SearchIcon}
                  />
                  <input
                    value={inputValue}
                    onChange={(e) => {
                      onSearch(e.target.value);
                      if (isDropdownOpen === false) {
                        setIsDropdownOpen(true);
                      }
                      setInputValue(e.target.value);
                    }}
                    className={classes.input}
                    placeholder={placeholder}
                  />
                </div>
              </>
            )}
            <img
              src={ArrowIcon}
              className={cn(classes.icon, isDropdownOpen && classes.rotate)}
              alt={"arrow icon"}
            />
          </button>
          <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
            {loading ? (
              <div className={classes.loadingWrapper}>
                <ReactLoading
                  type={"spin"}
                  color={"#20a8d8"}
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              <ul>
                {options &&
                  !!options.length &&
                  options.map((option, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          console.log("option", option);
                          onSelect(option);
                          setIsDropdownOpen(false);
                        }}
                      >
                        <a>{option.label}</a>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </ClickOutside>
  );
};

export default FilterSelector;
