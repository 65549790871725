import React, { useState, useEffect } from "react";
import { CProgress, CPopover } from "@coreui/react";
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//action start
import {
  getContactorListRequest,
  getContractorStats,
} from "store/supervisor/actions";
//action end

//selectors start
import {
  makeSelectContractorList,
  makeSelectError,
  makeSelectLoading,
} from "store/supervisor/selector";
import { makeSelectSupervisor } from "store/auth/selector";

//styles
import classes from "../people.module.scss";

//utils
import { isNil, isEmpty } from "lodash";
import { msToTime } from "utils/helper";
import { format, parseISO } from "date-fns";
import { contractorIndicator } from "utils/enums";
const formatDateTime = (val) =>
  !isNil(val) ? format(parseISO(val), `MM/dd/yyyy`) : "-";

const getStatusColor = (type) => {
  switch (type) {
    case "not_permitted":
      return "#E26C82";
    case "permitted":
      return "#03CE91";
    case "pending_induction":
      return "#BB6BD9";
    case "renewal_required":
      return "#F2C75E";
    default:
  }
};

const ContractorList = ({
  contractors,
  getContractorList,
  history,
  loading,
}) => {
  const [contractorList, setContractorList] = useState([]);

  const [rawList, setRawList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [PAGE_COUNT, setPageCount] = useState(0);

  const [searchState, setSearchState] = useState("");

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getContractorList({
        page: 1,
        searchItem: "",
        sort: "",
      });
      setSelectedPage(1);
    }
  };

  useEffect(() => {
    getContractorList({
      page: 1,
      searchItem: "",
      sort: "",
    });
  }, []);

  const contractorTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a?.id,
        email: a?.email,
        values: [
          {
            id: a?.id,
            raw: item,
            value: `${a?.first_name} ${a.last_name}`,
          },
          {
            value: a?.company_data?.company,
          },
          {
            value: !isEmpty(a?.site?.region) ? a.site.region : "-",
          },
          {
            value: !isNil(a?.status?.status) ? a.status.status : "-",
            component: (
              <div className={classes.statusWrapper}>
                <div
                  className={classes.status}
                  style={{
                    backgroundColor: getStatusColor(a?.status?.status),
                  }}
                />
              </div>
            ),
          },
          {
            value:
              !isNil(a.status?.renewal) && !isEmpty(a.status?.renewal)
                ? formatDateTime(a.status.renewal)
                : "-",
          },
          {
            value: !isEmpty(a.onsite) || !isEmpty(a.onsite) ? a.onsite : "-",
            component: (
              <div className={classes.statusWrapper}>
                {isEmpty(a.onsite) ? (
                  <span>-</span>
                ) : !isNil(a?.onsite?.checkout) ||
                  !isEmpty(a?.onsite?.checkout) ||
                  isNil(a?.onsite?.checkin) ? (
                  <div className={classes.statusGray} />
                ) : (
                  <span className={classes.checkinStatus}>
                    {msToTime(
                      Math.abs(new Date() - new Date(a?.onsite.checkin))
                    )}
                  </span>
                )}
              </div>
            ),
          },
        ],
      };
    });

    const labels = [
      { label: "Name" },
      { label: "Company" },
      { label: "Branch" },
      { label: "Status" },
      { label: "Renewal" },
      { label: "On Site" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) => {
        history.push(
          `/supervisor-sites-contractors-detail?id=${val.id}&returnUrl=supervisor-people-contractors`
        );
      },
    },
  ];

  useEffect(() => {
    if (!isNil(contractors)) {
      setContractorList(contractorTableData(contractors.results));
      const newRows = contractors?.data?.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      setRawList(contractors?.data);
      setPageCount(contractors?.number_of_pages);
    }
  }, [contractors]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getContractorList({
        page: 1,
        searchItem: event.target.value,
        sort: "",
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.indicatorWrapper}>
          {contractorIndicator.map((item) => (
            <div className={classes.indicatorItem}>
              <div style={{ background: item.color }} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        <div className={classes.tableWrapper}>
          <Table
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={contractorList}
            rowActions={rowActions}
            clickable={true}
            apiSort
          />
        </div>
        <Pagination
          totalCount={contractors?.count}
          itemsPerPage={contractors?.items_per_page}
          onChange={(val) => {
            getContractorList({
              page: val,
              searchItem: searchState,
              sort: "",
            });
            setSelectedPage(val);
          }}
          currentPage={selectedPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  contractors: makeSelectContractorList(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getContractorList: (val) => dispatch(getContactorListRequest(val)),
    getContactorStatistics: (val) => dispatch(getContractorStats(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorList);
