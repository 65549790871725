import React, { useState, useEffect } from "react";
import classes from "./drawer.module.scss";
import CloseIcon from "assets/icons/closeIcon.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeSelectProgress } from "store/progress/selector";
import { makeSelectCheckInStatus } from "store/auth/selector";
import { makeSelectSelectedLanguage } from "store/language/selector";

import { setSelectedStep } from "store/steps/actions";
import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  resetAssesmentResult,
} from "store/assesment/actions";

import { setProgressRequest, getProgressRequest } from "store/progress/actions";
import { withRouter } from "react-router-dom";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";

import { msToTime } from "utils/helper";
import { isNil } from "lodash";

const DrawerMenu = ({
  progressData,
  show,
  action,
  setStep,
  history,
  checkInStatus,
  setCurrentSectionReq,
  getSectionTimeReq,
  selectedLanguage,
}) => {
  const LifeSavingRules = [
    { id: 3, text: "Drugs & Alcohol" },
    { id: 4, text: "Exclusion Zones" },
    { id: 5, text: "Speed Limits" },
    { id: 6, text: "Working with Scaffold" },
    { id: 7, text: "Fall Protection" },
    { id: 8, text: "Dust and Harmful Substances" },
  ];

  const isCheckInPage = history.location.pathname === "/check-in";
  const [time, setTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    const counter = () => {
      setTimeDiff(timeDiff + 1000);
      setTime(msToTime(timeDiff + 1000));
    };
    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (!isNil(checkInStatus)) {
      var date = new Date(checkInStatus.check_in);
      var now = new Date();

      const diffTime = Math.abs(date - now);
      setTimeDiff(diffTime);
      setTime(msToTime(diffTime));
    }
  }, []);

  console.log("selected", selectedLanguage);

  return (
    <React.Fragment>
      <div style={{ height: "100vh" }}>
        {show && (
          <img
            alt={"Close Icon"}
            src={CloseIcon}
            className={classes.CloseIcon}
            onClick={action}
          />
        )}
        <div className={classes.Wrapper}>
          <span className={classes.Heading}>
            <FormattedMessage defaultMessage={"Menu"} id={"menu.title"} />
          </span>
          {!isCheckInPage && (
            <>
              <Link className={classes.Title} to="/welcome">
                <span className={classes.Title}>
                  <FormattedMessage
                    defaultMessage={"Welcome"}
                    id={"menu.welcome"}
                  />
                </span>
              </Link>
              <Link className={classes.Title} to="/take-a-look">
                <span className={classes.Title}>
                  <FormattedMessage
                    defaultMessage={"Take a Look Around"}
                    id={"menu.takealookaround"}
                  />
                </span>
              </Link>
              <span
                className={classes.ActiveSubTitle}
                onClick={() =>
                  (window.location = `/frl_vr/vr_experience_1.html?language=${selectedLanguage?.code}`)
                }
              >
                <FormattedMessage
                  defaultMessage={">Scene 1"}
                  id={"menu.takealookaround.item.1"}
                />
              </span>
              <span
                className={classes.ActiveSubTitle}
                onClick={() =>
                  (window.location = `/frl_vr/vr_experience_2.html?language=${selectedLanguage?.code}`)
                }
              >
                {" "}
                <FormattedMessage
                  defaultMessage={">Scene 2"}
                  id={"menu.takealookaround.item.2"}
                />
              </span>
              <span
                onClick={() => {
                  history.push("/induction");
                  action();
                  setStep({ id: false });
                }}
                className={classes.Title}
              >
                <FormattedMessage
                  defaultMessage={"Life Saving Rules"}
                  id={"menu.lifesavingrules"}
                />
              </span>

              <div className={classes.RulesContainer}>
                {LifeSavingRules.map((item, index) => (
                  <span
                    onClick={() => {
                      if (
                        (progressData && progressData?.section >= item.id) ||
                        item.id === 3
                      ) {
                        setCurrentSectionReq({
                          section: item.id,
                          callBack: () =>
                            getSectionTimeReq({
                              induction_id: "2",
                              section: item.id,
                            }),
                        });
                        setStep({ id: item.id });
                        action();
                        history.push(`/induction`);
                      } else {
                      }
                    }}
                    className={
                      (progressData && progressData.section >= item.id) ||
                      item.id === 3
                        ? classes.ActiveSubTitle
                        : classes.DisabledSubTitle
                    }
                  >
                    <FormattedMessage
                      id={`menu.lifesavingrules.item.${index + 1}`}
                      defaultMessage={`${index + 1}. ${item.text}`}
                    />
                  </span>
                ))}
              </div>
              <Link
                className={
                  progressData && progressData.section < 8
                    ? classes.DisabledTitle
                    : classes.Title
                }
                to={
                  progressData && progressData.section < 8
                    ? "#"
                    : "/card-system"
                }
              >
                <span
                  className={
                    progressData && progressData.section < 8
                      ? classes.DisabledTitle
                      : classes.Title
                  }
                >
                  <FormattedMessage
                    id={`menu.cardsystem`}
                    defaultMessage={"Card System"}
                  />
                </span>
              </Link>
              <Link
                className={
                  progressData && progressData.section < 8
                    ? classes.DisabledTitle
                    : classes.Title
                }
                to={
                  progressData && progressData.section < 8 ? "#" : "/site-rules"
                }
              >
                <span
                  c
                  className={
                    progressData && progressData.section < 8
                      ? classes.DisabledTitle
                      : classes.Title
                  }
                >
                  <FormattedMessage
                    id={`menu.siterules`}
                    defaultMessage={"Site Rules"}
                  />
                </span>
              </Link>
            </>
          )}
          {checkInStatus && (
            <Link className={classes.Title} to={"/check-out"}>
              <span c className={classes.Title}>
                <FormattedMessage
                  id={`menu.checkout`}
                  defaultMessage={"Check Out"}
                />{" "}
                {time}
              </span>
            </Link>
          )}
          <Link className={classes.Title} to={"/logout"}>
            <span c className={classes.Title}>
              <FormattedMessage id={`menu.logout`} defaultMessage={"Logout"} />
            </span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  checkInStatus: makeSelectCheckInStatus(),
  selectedLanguage: makeSelectSelectedLanguage(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: (val) => dispatch(setSelectedStep(val)),
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgressReq: (val) => dispatch(setProgressRequest(val)),
    resetCurrentResult: (val) => dispatch(resetAssesmentResult(val)),
    setCurrentSectionReq: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)
);
