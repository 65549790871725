import styled from "styled-components";
import { device } from "utils/helper/breakpoints";
const CardContainer = styled.div`
  border-radius: 20px;
  margin: 10px;
  display: grid;
  flex: unset;
  flex-direction: unset;
  width: 90%;
  height: 162px;
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  &:before {
    border-radius: 20px;
    background-color: ${(props) => props.color};
    opacity: 0.8;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  @media ${device.desktopL} {
    display: grid;
  }
  @media ${device.desktop} {
    display: grid;
  }
  @media ${device.laptopL} {
    display: grid;
  }
  @media ${device.laptop} {
    display: grid;
  }
  @media ${device.tablet} {
    display: flex;
    flex: 0 40%;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    display: grid;
    width: 90%;
  }
`;
const TitleWrapper = styled.div`
  margin: 10px 20px;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
const Title = styled.span`
  font-family: Open Sans;
  padding-left: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 2.6vh;
  line-height: 20px;
  text-align: start;
  color: #fff;
  position: relative;
  width: 100%;
  @media ${device.tablet} {
    font-size: 1.5vh;
    line-height: 30px;
  }

  @media ${device.tablet} and (orientation: landscape) and (max-height: 415px) {
    font-size: 1.5vw;
    line-height: 30px;
  }
`;
const MiddleArea = styled.div`
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
const ImageBox = styled.div`
  width: 75px;
  height: 75px;
  position: relative;
  justify-content: center;
  background-color: #ffffff;
  z-index: 3;
  align-item: center;
  border-radius: 5px;
`;
const Thumbnail = styled.img`
  margin: 5px 5px;
  height: 65px;
`;
const CorrectBox = styled.div`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  justify-content: flex-end;
`;
const ProgressBar = styled.div`
  display: flex;
  position: relative;
  width: 90%;
  height: 8px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.247);
  margin: 0px auto;
`;
const Progress = styled.div`
  display: flex;
  position: relative;
  height: 8px;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  width: ${(props) => props.progress};
`;

export {
  CardContainer,
  TitleWrapper,
  Title,
  MiddleArea,
  ImageBox,
  Thumbnail,
  CorrectBox,
  ProgressBar,
  Progress,
};
