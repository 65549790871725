// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CHECK_IN_REQUEST,
  CHECK_IN_REQUEST_SUCCESS,
  CHECK_IN_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/public";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* checkIn(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();

    params.append("site", values.site);

    const access_token = window.localStorage.getItem("access_token");

    delete axios.defaults.headers.common["X-Access-Token"];
    delete axios.defaults.headers.common["X-Token"];

    if (!isNil(values.token) && isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = values.token;
    } else {
      axios.defaults.headers.common["X-Access-Token"] = access_token;
    }

    const response = yield call(axios.post, "/check-in/", params);

    if (response.data.success === 0) {
      if (values.login && response.data.type === "pending_induction") {
        yield put(push(`/welcome`));
      }

      if (values.login && values.site && response.data.type === "error") {
        yield put(push(`/check-in?site=${values.site}`));
      }

      if (
        values.login &&
        values.site &&
        response.data.type === "incomplete_induction"
      ) {
        yield put(push(`/welcome`));
      }

      if (!isNil(response?.data?.checkin_id)) {
        yield put(
          push(`/check-in?site=${values.site}&id=${response?.data?.checkin_id}`)
        );
      }

      yield put({
        type: CHECK_IN_REQUEST_ERROR,
        data: { siteID: values.site, ...response.data },
      });
    } else if (response.data.error) {
      yield put({
        type: CHECK_IN_REQUEST_ERROR,
        data: response.data.error,
      });
    } else if (response.data.success === 1) {
      if (values.login) {
        if (response.data.type === "pending_induction") {
          yield put(push(`/welcome`));
        } else {
          yield put(push(`/check-in?site=${values.site}`));
        }
      }

      if (!isNil(response?.data?.checkin_id)) {
        yield put(
          push(`/check-in?site=${values.site}&id=${response?.data?.checkin_id}`)
        );
      }
      yield put({
        type: CHECK_IN_REQUEST_SUCCESS,
        data: { siteID: values.site, ...response.data },
      });
    } else {
      yield put({
        type: CHECK_IN_REQUEST_SUCCESS,
        data: response.data.languages,
      });
    }
  } catch (error) {
    yield put(
      push(`/language-selection?site=${data.payload.site}&redirect=check-in`)
    );

    yield put({
      type: CHECK_IN_REQUEST_ERROR,
      data: null,
    });
  }
}

export default function* checkInSaga() {
  yield takeLatest(CHECK_IN_REQUEST, checkIn);
}
