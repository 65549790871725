import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
// sidebar nav config
import navigation from "./_nav";
import classes from "./nav.module.scss";

//const Logo = process.env.PUBLIC_URL + "/logo.png";
const Logo = process.env.PUBLIC_URL + "/logo/frl_logo.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow?.sidebarShow);

  return (
    <CSidebar
      style={{ backgroundColor: "#2f353a" }}
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none flex " to="/">
        <img
          alt={"Logo"}
          style={{
            width: "86px",
            display: "flex",
            alignSelf: "center",
            marginLeft: "-52px",
            marginTop: "10px",
            marginBottom: "10px",
            justifySelf: "flex-start",
          }}
          src={Logo}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <div className={classes.sidebar}>
          <CCreateElement
            items={navigation}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </div>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
