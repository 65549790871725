import React, { useState } from "react";
import classes from "./table.module.scss";
import Checkbox from "../checkbox";
// import SortIcon from "../../icons/sort.svg";
// import SearchIcon from "../../icons/search.svg";
// import ConfigIcon from "./config.svg";
import { isEmpty, isNil } from "lodash";
import ReactLoading from "react-loading";

const Table = ({
  data,
  rowActions,
  selectedRows,
  setSelectedRows,
  sortByDesc,
  sortConfig,
  apiSort,
  isSearchable,
  searchState,
  onSearch,
  loading,
  handleKeyDown,
  width,
  clickable,
  style,
  onRowClick,
}) => {
  const SortIcon = window.location.origin + "/tableIcons/sort.svg";
  const SearchIcon = window.location.origin + "/tableIcons/search.svg";
  const ConfigIcon = window.location.origin + "/tableIcons/config.svg";
  const [onHover, setHover] = useState(null);

  const onClickRow = (col) => {
    if (clickable) {
      if (!isEmpty(rowActions)) {
        rowActions[0].action(col);
      } else {
        onRowClick(col);
      }
    }
  };

  return (
    <div className={classes.container} style={style}>
      {isSearchable && (
        <div className={classes.searchBox}>
          <img
            alt={"search icon"}
            className={classes.searchIcon}
            src={SearchIcon}
          />
          <input
            value={searchState}
            onChange={(e) => onSearch(e)}
            className={classes.searchInput}
            placeholder={"Search"}
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
      <table className={classes.table} style={{ width: width && width }}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <>
            <thead className={classes.thead}>
              <tr className={classes.tr}>
                {selectedRows && (
                  <th
                    className={classes.headElement}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                      width: selectedRows ? "60px" : "20px",
                      maxWidth: "60px",
                    }}
                  >
                    <Checkbox
                      onClick={() => {
                        const newRows = selectedRows?.map((item) => {
                          return {
                            ...item,
                            checked:
                              selectedRows?.filter((item) => item.checked)
                                .length === selectedRows?.length
                                ? false
                                : true,
                          };
                        });
                        setSelectedRows(newRows);
                      }}
                      checked={
                        selectedRows?.filter((item) => item.checked).length ===
                        selectedRows?.length
                          ? true
                          : false
                      }
                    />
                  </th>
                )}

                {data &&
                  data.labels &&
                  data.labels.map((item) => (
                    <th
                      style={{
                        width: item.width && item.width,
                        color: item.disabled && "grey",
                        paddingLeft: "10px",
                      }}
                      className={classes.headElement}
                    >
                      {!item.sortable ? (
                        <> {item.label}</>
                      ) : (
                        <>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              apiSort
                                ? apiSort(item.sortType)
                                : sortByDesc(item.key)
                            }
                          >
                            <span>{item.label}</span>
                            {sortConfig && sortConfig.key === item.key && (
                              <img
                                alt={"sort icon"}
                                className={
                                  sortConfig.direction === "descending"
                                    ? classes.asc
                                    : classes.dsc
                                }
                                src={SortIcon}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                <th
                  className={classes.headElement}
                  style={{ width: "50px" }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.columns &&
                data.columns.map((col, index) => (
                  <tr
                    onMouseLeave={() => setHover(null)}
                    className={clickable ? classes.clickable : classes.columns}
                  >
                    {selectedRows && (
                      <td
                        className={classes.row}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: selectedRows ? "60px" : "20px",
                          height: "100%",
                          paddingTop: "20px",
                          zIndex: 99,
                          maxWidth: "60px",
                        }}
                      >
                        <Checkbox
                          onClick={() => {
                            const selrows = selectedRows.map((item, i) => {
                              return {
                                ...item,
                                checked:
                                  col.id === item.id
                                    ? !item.checked
                                    : item.checked,
                              };
                            });
                            setSelectedRows(selrows);
                          }}
                          checked={
                            selectedRows &&
                            !isNil(selectedRows.find((a) => a.id === col.id))
                              ? selectedRows.find((a) => a.id === col.id)
                                  .checked
                              : false
                          }
                        />
                      </td>
                    )}

                    {col &&
                      col.values.map((a, i) => (
                        <td
                          onClick={() => onClickRow(col)}
                          className={classes.row}
                          style={{
                            height: !a.component && "63px",
                            paddingLeft: "10px",
                          }}
                        >
                          {a.hoverText ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignSelf: "center",
                                  height: !a.component && "100%",
                                  marginTop: !a.component && "28px",
                                  color: a.disabled && "#40507E",
                                  opacity: a.disabled && "0.5",
                                }}
                              >
                                <div className={classes.tooltip}>
                                  {a.value}
                                  <span className={classes.tooltiptext}>
                                    {a.hoverText}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                height: !a.component && "100%",
                                marginTop: !a.component && "28px",
                                color: a.disabled && "#40507E",
                                opacity: a.disabled && "0.5",
                              }}
                            >
                              {a.component ? a.component : a.value}
                            </div>
                          )}
                        </td>
                      ))}
                    {rowActions && (
                      <td
                        className={classes.row}
                        style={{
                          height: "63px",
                          width: "50px",
                          zIndex: 999,
                          paddingLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            height: "100%",
                            marginTop: "28px",
                            zIndex: 99999,
                          }}
                        >
                          <div
                            className={classes.menu}
                            onMouseEnter={(e) => {
                              const rect = e.target.getBoundingClientRect();

                              setHover({
                                index: index,
                                left: rect.x + rect.width / 2,
                                top: rect.y + window.scrollY,
                                col: col,
                              });
                            }}
                          >
                            <img alt={"config icon"} src={ConfigIcon} />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              {onHover && (
                <div
                  onMouseEnter={() => setHover(onHover)}
                  onMouseLeave={() => setHover(null)}
                  className={classes.configContent}
                  style={{
                    top: onHover.top,
                    left: onHover.left - 100,
                    height: rowActions && `${rowActions.length * 34}px`,
                  }}
                >
                  {rowActions.map((item, index) => (
                    <span
                      onClick={() => item.action(onHover.col)}
                      key={onHover.index}
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              )}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default Table;
